<template>
  <main>
    <div
      v-if="offerings && offerings.length > 0"
      class="container page-content"
    >
      <h3>Current Offerings</h3>
      <div class="row align-items-start">
        <div class="col-12 col-lg-9">
          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-5">
            <div v-for="offering in offerings" :key="offering.id" class="col">
              <OfferingSingle :offering="offering" />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-2">
          <TagCloud :offerings="offerings" />
        </div>
      </div>
    </div>
    <div class="container page-content text-center" v-else>
      <p>
        There are no active offerings available for investment at this time.
        There are some offerings in the works, so check back soon.
      </p>
    </div>
  </main>
  <Footer v-if="offerings" />
</template>

<script>
import OfferingSingle from "@/components/offerings/OfferingSingle.vue";
import getCollection from "@/composables/getCollection";
import TagCloud from "@/components/tags/TagCloud.vue";
import Footer from "@/components/navigation/Footer.vue";

export default {
  components: { OfferingSingle, Footer, TagCloud },
  setup() {
    const { error, documents: offerings } = getCollection(
      "offerings",
      [
        ["featured", ">=", 0],
        ["published", "==", true]
      ],
      ["featured", "desc"]
    );

    return { error, offerings };
  }
};
</script>

<style>
</style>